<template>
  <div id="app">
    <!-- <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay> -->

    <!-- loading skeleton Start -->
    <!-- <v-col v-show="loading == true">
      <v-sheet
        height="565"
        class="pa-2 mb-n5"
        max-width="6000"
        :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'"
      >
        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="400"
              type="heading"
            ></v-skeleton-loader>
            <v-spacer></v-spacer>
            
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
              class="ml-2"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
        
        <div>
          <v-skeleton-loader
            tile
            height="50"
            type="image"
            class="mt-1"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-skeleton-loader
              type="image"
              width="250"
              height="20"
              class="rounded-xl mx-n1 mt-1 mb-1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>

        <div>
          <v-skeleton-loader
            tile
            height="200"
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
            tile
            height="132"
            type="image"
            class="mb-3"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="800"
              type="heading"
              class="mt-1 mx-n2"
            ></v-skeleton-loader>

            <v-spacer></v-spacer>

            <v-skeleton-loader
              type="image"
              width="170"
              height="20"
              class="rounded-xl mx-n1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
      </v-sheet>
    </v-col> -->
    <!-- loading skeleton End -->

    <!-- <v-skeleton-loader
    class="mx-2"
      max-width="5000"
      height="80"
      type="actions"
      v-show="loading == true"
    ></v-skeleton-loader>
    <v-skeleton-loader
      max-width="200"
      class="mt-n15"
      type="card-heading"
      v-show="loading == true"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-2 my-n2"
      max-width="5000"
      height="80"
      type="image"
      v-show="loading == true"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="my-n5"
      max-width="5000"
      height="80"
      type="table-heading"
      v-show="loading == true"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-2"
      max-width="5000"
      type="image,actions"
      v-show="loading == true"
    ></v-skeleton-loader> -->

    <!-- Alert Tidak Punya Akses Halaman -->
    <v-col v-show="this.valid == false">
      <v-alert text type="error">
        Kamu tidak punya akses Ke halaman "Cabang"
      </v-alert>
    </v-col>

      <!-- Alert Data Tersimpan -->
      <v-alert style="position:fixed;z-index:2;right:20px;"
        dismissible
        color="success"
        border="left"
        elevation="4"
        colored-border
        v-model="Alert"
        transition="slide-x-transition"
      >
        <v-icon class="mr-1">check-circle</v-icon>
        Data Berhasil Disimpan
      </v-alert>

      <!-- Alert Data Sudah Terhapus -->
      <v-alert style="position:fixed;z-index:2;right:20px;"
        dismissible
        color="error"
        border="left"
        elevation="4"
        colored-border
        v-model="AlertDataTerhapus"
        transition="slide-x-transition"
      >
        Data Berhasil Dihapus
      </v-alert>

      <!-- Alert Data Berhasil Terubah -->
      <v-alert style="position:fixed;z-index:2;right:20px;"
        dismissible
        color="info"
        border="left"
        elevation="4"
        colored-border
        v-model="AlertBerhasilTerubah"
        transition="slide-x-transition"
      >
        Data Berhasil Diubah
      </v-alert>

      <!-- ---------------------------------------------------- -->

      <v-container fluid v-show="this.valid == true">
        <v-card id="print" tile outlined class="elevation-3 pa-2">
          <v-toolbar
            flat
            outlined
            height="55"
            color="dark"
            v-resize="onResize"
          >
            <v-toolbar-title>
              Data Cabang
            </v-toolbar-title>
            <v-divider inset vertical class="ml-2"></v-divider>
            <v-spacer></v-spacer>

            <!-- Menu Export Data -->
            <div class="text-center mx-2">
              <v-menu
                v-model="MenuExport"
                :close-on-content-click="false"
                :nudge-width="1"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <!-- Btn Export Mode Biasa -->
                  <v-btn
                    @click="MenuExport = !MenuExport"
                    class="text-capitalize"
                    v-show="mobile == false"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    text
                    :disabled="loading == true"
                  >
                    <v-icon class="ml-n2 mr-1">mdi-table-arrow-right</v-icon>
                    <span class="subtitle-1">Export Data</span>
                    <v-icon class="ml-1 mr-n3">{{ MenuExport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </template>

                <v-card outlined height="142" class="pa-2" v-show="MenuExport">
                  <!-- PDF -->
                  <div class="mb-2">
                    <v-btn
                      text
                      block
                      outlined
                      @click.native="PdfExport"
                      class="text-capitalize subtitle-1"
                    >
                      Pdf
                      <v-icon right>mdi-adobe-acrobat</v-icon>
                    </v-btn>
                  </div>

                  <!-- EXCEL -->
                  <div class="mb-2">
                    <v-btn
                      text
                      block
                      outlined                       
                      @click.native="excelExport"
                      class="text-capitalize subtitle-1"
                    >
                      Excel
                      <v-icon right>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </div>

                  <!-- print -->
                  <div>
                    <v-btn
                      text
                      block
                      outlined
                      @click.native="print"
                      class="text-capitalize subtitle-1"
                    >
                      print
                      <v-icon right>mdi-printer</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </v-menu>

              <!-- Btn Export Dialog Mode Mobile -->
              <v-dialog
                v-model="DialogPilihExport"
                max-width="200"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="mobile == true"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    text
                    fab
                    :disabled="loading == true"
                  >
                    <v-icon>mdi-table-arrow-right</v-icon>
                  </v-btn>
                </template>
                <v-card class="rounded-lg">
                  <v-toolbar dense>
                    <v-layout justify-center>
                      <v-toolbar-title>
                        Pilih Export File
                      </v-toolbar-title>
                    </v-layout>
                  </v-toolbar>
                  <v-card class="pa-2">
                    <v-btn text block outlined class="text-capitalize subtitle-1 mb-2" @click.native="PdfExport">
                      Pdf
                      <v-icon right>mdi-adobe-acrobat</v-icon>
                    </v-btn>

                    <v-btn text block outlined class="text-capitalize subtitle-1" @click.native="excelExport">
                      Excel
                      <v-icon right>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </v-card>
                </v-card>
              </v-dialog>
            </div>

            <!-- Dialog Tambah Cabang -->
            <v-dialog
              v-model="DialogTambahCabang"
              max-width="400px"
              scrollable
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- Btn Tambah Mode Desktop -->
                <v-btn
                  class="text-capitalize mr-n2"
                  v-show="mobile == false && ValidCreate == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  text
                  :disabled="loading == true"
                >
                  <v-icon class="ml-n2 mr-1">mdi-plus-thick</v-icon>
                  <span class="mr-n1 subtitle-1">Tambah</span>
                </v-btn>

                <!-- Btn Tambah Mode Mobile -->
                <v-btn 
                  class="mr-n2"
                  v-show="mobile == true && ValidCreate == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                  text
                  fab
                  :disabled="loading == true"
                >
                  <v-icon>mdi-plus-thick</v-icon>
                </v-btn>
              </template>
              <v-card class="rounded-lg">
                <v-toolbar flat height="64">
                  <v-toolbar-title class="title">
                    <span class="text-h5 mx-1">{{ formTitleJabatan }}</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    @click="KeluarDialogCabang"
                  >
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-divider></v-divider>
                
                <v-card-text>
                  <div class="mr-n3 ml-n3 mb-n2 mt-3">
                    <v-sheet outlined class="rounded-lg pa-5">
                      <v-row>  
                        <v-col cols="12" sm="12" md="12" lg="12">
                          <v-text-field
                            dense
                            label="Kode Cabang"
                            color="dark"
                            v-model="editedItem.KodeCabang"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" lg="12">
                          <v-text-field
                            dense
                            label="Nama Cabang"
                            color="dark"
                            v-model="editedItem.NamaCabang"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" lg="12">
                          <v-text-field
                            dense
                            label="Alamat Cabang"
                            color="dark"
                            v-model="editedItem.AlamatCabang"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" lg="12">
                          <v-autocomplete
                            dense
                            label="Kode Perusahaan"
                            color="dark"
                            :items="DataPerusahaan"
                            :item-text="TextNamaPerusahaan"
                            item-value="KodePerusahaan"
                            v-model="editedItem.KodePerusahaan"
                          ></v-autocomplete>
                          <!-- <v-text-field
                            dense
                            label="Kode Perusahaan"
                            color="dark"
                            v-model="editedItem.KodePerusahaan"   
                          ></v-text-field> -->
                        </v-col>

                        <v-col cols="12" sm="12" md="12" lg="12">
                          <v-text-field
                            dense
                            label="Latitude"
                            color="dark"
                            v-model="editedItem.Latitude"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" lg="12">
                          <v-text-field
                            dense
                            label="Longitude"
                            color="dark"
                            v-model="editedItem.Longitude"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" lg="12">
                          <v-text-field
                            dense
                            label="Radius(M)"
                            color="dark"
                            v-model="editedItem.Radius"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-sheet>    
                  </div>               
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <span class="d-inline-block text-truncate">
                    <label>Status : <span>{{Status}}</span></label>
                  </span>
                  
                  <v-spacer></v-spacer>

                  <v-btn
                    dark
                    depressed
                    color="red darken-4 mx-n2"
                    class="text-capitalize"
                    @click="Simpan"
                  >
                    <v-icon left>mdi-content-save</v-icon>
                    <span class="mr-1">Simpan</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <div v-show="loading == true">
              <v-progress-linear
                :active="loading"
                :indeterminate="loading"
                absolute
                bottom
                height="6"
                color="red darken-4"
              ></v-progress-linear>
            </div>
          </v-toolbar>

          <div>
            <ejs-grid
              ref='gridcabang'
              height="100%"
              gridLines='Both'
              id="cabangview"
              :dataSource="DataCabang"
              :allowPaging="true"
              :allowSorting='true'
              :allowResizing='true'
              :allowGrouping='true'
              :toolbar='toolbarOptions'
              :commandClick="commandClick"
              :pageSettings='pageSettings'
              :allowExcelExport='true'
              :allowPdfExport='true'
            >
              <e-columns>
                <e-column headerText="Aksi" textAlign='Center' width="120" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                  <div class="btn-group">
                    <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                      <i class="fa fa-pencil"></i>
                    </v-btn>
                    <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                      <i class="fa fa-trash"></i>
                    </v-btn>
                  </div>
                </e-column>
                <e-column field='KodeCabang' headerText='Kode Cabang' textAlign='Left' width=150></e-column>
                <e-column field='NamaCabang' headerText='Nama Cabang' width=300></e-column>
                <e-column field='Alamat' headerText='Alamat' width=160></e-column>
                <e-column field='perusahaan.NamaPerusahaan' headerText='NamaPerusahaan' width=150></e-column>
                <e-column field='KodePerusahaan' headerText='Kode Perusahaan' :visible="false" width=160></e-column>
                <e-column field='Latitude' headerText='Latitude'  width=160></e-column>
                <e-column field='Longitude' headerText='Longitude'  width=160></e-column>
                <e-column field='Radius' headerText='Radius(M)'  width=120></e-column>
                <e-column field='DiBuatOleh' headerText='DiBuatOleh' textAlign='Left' width=150></e-column>
                <e-column field='DiBuatTgl' headerText='DiBuatTgl' textAlign='Left' width=150></e-column>
                <e-column field='DiUbahOleh' headerText='DiUbahOleh' textAlign='Left' width=150></e-column>
                <e-column field='DiUbahTgl' headerText='DiUbahTgl' textAlign='Left' width=150></e-column>
              </e-columns>
            </ejs-grid>
          </div>
        </v-card>
      </v-container>
  </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Sort, Group, Resize, Toolbar, Search, CommandColumn, ExcelExport, PdfExport } from "@syncfusion/ej2-vue-grids";
import api from "@/services/http";
Vue.use(GridPlugin);

export default {
  data() {
    return {
      mobile:null,
      valid:null,
      passbaru:null,
      Permission:[],
      DataPerusahaan:[],
      ValidCreate:false,
      KodeCabang:null,
      textAktif:true,
      umenu:[],
      verifpassbaru:null,
      loading:true,
      Kode:null,
      DialogPilihExport:false,
      windowSize: {x: 0, y: 0},
      MenuExport: false,
      commands: [],
      customAttributes : {class: 'customcss'},
      Alert:false,
      akun:[],
      usermenu:null,
      AlertDataTerhapus:false,
      AlertBerhasilTerubah:false,
      user:[],
      token:null,
      DialogTambahCabang: false,
      form: new FormData,
      editedIndex: -1,

      defaultItem: {
        KodeCabang: "",
        NamaCabang: "",
        AlamatCabang: "",
        KodePerusahaan: "",
        Latitude:"",
        Longitude:"",
        Radius:"",
      },
      editedItem: {
        KodeCabang: "",
        NamaCabang: "",
        AlamatCabang: "",
        KodePerusahaan: "",
        Latitude:"",
        Longitude:"",
        Radius:"",
      },
      toolbarOptions: ['Search'],
      DataCabang: [],
      pageSettings: {pageSize: 10, pageSizes :['5','10','15','20','50','All']},
    };
  },
  

  provide: {
    grid: [Page, Sort, Group, Resize, Toolbar, CommandColumn, Search,ExcelExport,PdfExport ]
  },

 async mounted(){
    this.token = localStorage.getItem('token')
    this.user = JSON.parse(localStorage.getItem('user'))
    if (this.Permission.some(a => a == 'R')) {
      this.valid = true
      this.getdata()
    }else{
      this.loading = false
      this.valid = false
    }
    if (this.Permission.some(a => a == 'C')) {
     this.ValidCreate = true
    }
    if (this.Permission.some(a => a == 'U')) {
     this.commands.push({buttonOption: { cssClass: "e-flat Edit", iconCss: "e-edit e-icons"} })
    }
    if (this.Permission.some(a => a == 'D')) {
     this.commands.push({ buttonOption: { cssClass:"e-flat Delete", iconCss: "e-delete e-icons"} })
    }
    
  },

  created(){
    //get action for this page
    let permisi = this.$route.params.Permission
    this.Permission = permisi == null ? [] : permisi.split(',')
    },

  computed: {
    formTitleJabatan() {
      return this.editedIndex === -1 ? 'Tambah Cabang Baru' : 'Ubah Data Cabang'
    },
    Status () {
      return this.editedIndex === -1 ? 'Baru' : 'Ubah'
    },
  },
  
  watch: {
    windowSize(){
      if (this.windowSize.x < 450) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },

    DialogTambahCabang (val) {
      val || this.KeluarDialogCabang()
    },

    Alert(){
      this.AlertItem()
    },
    AlertDataTerhapus(){
      this.AlertItem()
    },
    AlertBerhasilTerubah(){
      this.AlertItem()
    },
  },

  methods: {
    print(){
      // Get HTML to print from element
        const prtHtml = document.getElementById('print').innerHTML;

        // Get all stylesheets HTML
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }

        // Open the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
    },
    TextNamaPerusahaan(x){
      return x.KodePerusahaan + ' — ' + x.NamaPerusahaan
    },
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    cariKode(Kode){
      if (Kode != null) {
          this.textAktif = false
        }else{
          this.textAktif = true
        }
      if (Kode == null || Kode == '' || Kode.length == 0) {
        this.editedIndex = -1
          this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }else{
      this.Kode = Kode.toUpperCase()
      let a = this.DataCabang.filter( function(item){return (item.Kode == Kode.toUpperCase());} );
      if (a.length > 0) {
          this.editedIndex = 1;
          this.textAktif = false
          this.editedItem.Nama = a[0].Nama
        }
      }
    },
  async saya(){
       if (this.$menu == undefined) {
         await api.get('/menu?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
        res=>{
          if (res) {
            this.akun = res.data
            let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
            //do noting
          }
        }).catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
            this.logout()
          }
        })
       }else{
        this.akun = this.$menu
        let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
       }     
    },

    Simpan(){
      if(this.formTitleJabatan === "Tambah Cabang Baru"){
        this.Toast('Data Disimpan')
          api.post('/cabang?token='+this.token,{
            KodeCabang: this.editedItem.KodeCabang,
            NamaCabang: this.editedItem.NamaCabang,
            Alamat: this.editedItem.AlamatCabang,
            KodePerusahaan: this.editedItem.KodePerusahaan,
            Latitude:this.editedItem.Latitude,
            Longitude:this.editedItem.Longitude,
            Radius:this.editedItem.Radius
            })
            .then((res)=>{
              if (res) {
                // this.Alert = true
                this.getdata()
              } else {
                //
              }
            })
      }else if(this.formTitleJabatan === "Ubah Data Cabang"){
        this.Toast('Data Diubah')
         api.put("/cabang/"+this.editedItem.KodeCabang+"/?token="+this.token,{
            KodeCabang: this.editedItem.KodeCabang,
            NamaCabang: this.editedItem.NamaCabang,
            Alamat: this.editedItem.AlamatCabang,
            KodePerusahaan: this.editedItem.KodePerusahaan,
            Latitude:this.editedItem.Latitude,
            Longitude:this.editedItem.Longitude,
            Radius:this.editedItem.Radius
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.getdata()
              // this.AlertBerhasilTerubah = true
						}
						
					})
      }
      this.KeluarDialogCabang()    
    },

    commandClick: function(args) {
      if (args.target.classList.contains("custombutton")){
        // let data = JSON.stringify(args.rowData);
        // console.log(args.rowData);
      } else if (args.target.classList.contains("Delete")){
        var r = confirm("Yakin Hapus Data?");
        if (r == true) { 
          this.Toast('Data Dihapus')
          api
            .delete("/cabang/"+args.rowData.id+'?token='+this.token)
            .then((res) =>{
              // console.log(res);
              if (res) {
                this.getdata()
              this.AlertDataTerhapus = true
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (args.target.classList.contains("Edit")){
        // console.log(args.rowData)
        this.KodeCabang = args.rowData.KodeCabang
        // let data = args
            this.editedIndex = 1;
            // console.log(data)
            this.editedItem.KodeCabang = args.rowData.KodeCabang
            this.editedItem.NamaCabang = args.rowData.NamaCabang
            this.editedItem.AlamatCabang = args.rowData.Alamat
            this.editedItem.KodePerusahaan = args.rowData.KodePerusahaan
            this.editedItem.Latitude = args.rowData.Latitude
            this.editedItem.Longitude = args.rowData.Longitude
            this.editedItem.Radius = args.rowData.Radius
            this.DialogTambahCabang = true
      }
    },

    PdfExport(){
      let data = document.getElementById('cabangview').ej2_instances[0];
      // console.log(data)
      data.pdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName:"ReportJabatan.pdf",
      }
      data.columns[4].visible = false
      data.columns[5].visible = false
      data.columns[6].visible = false
      data.columns[7].visible = false
      this.$refs.gridcabang.pdfExport(data.pdfExportProperties);
    },
    excelExport(){
      this.$refs.gridcabang.excelExport();
    },

    KeluarDialogCabang () {
      this.DialogTambahCabang = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.Kode = null
        this.textAktif = true
      })
    },

    // beforeExport: function(args) {
      
    // },

    getdata(){
      if (this.valid == true) {
        api.get('/cabang?token='+this.token,
        ).then(
				res => {
          this.getPerusahaan()
					console.log(res);
          this.DataCabang = res.data
          this.loading = false
				},
				err => {
					console.log(err);
				}
			)
      }
      
    },

    getPerusahaan(){
        api.get('/perusahaan?token='+this.token,
        ).then(
				res => {
          this.DataPerusahaan = res.data
				},
				err => {
					console.log(err);
				}
			)    
    },

    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true})
    },

    Reset() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    logout(){
        api.post('/logout', this.token)
        .then(res=>{
          // console.log(res)
          if (res) {
            //do nothing
          }
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.reload(false)
      },

    AlertItem(){
      window.setInterval(() => {
        this.Alert = false;
        this.AlertDataTerhapus = false;
        this.AlertBerhasilTerubah = false;
      }, 3000)
    },
  },
}
</script>

